//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { databaseInfoEdit } from '@/api/modular/dbs/databaseInfoManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      jdbcDriverList: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.visible = true
      this.sysDictTypeDropDown()
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            dbName: record.dbName,
            jdbcDriver: record.jdbcDriver,
            jdbcUrl: record.jdbcUrl,
            userName: record.userName,
            password: record.password,
            remarks: record.remarks
          }
        )
      }, 100)
    },
    sysDictTypeDropDown () {
      const params = {
        code: 'jdbc_driver'
      }
      sysDictTypeDropDown(params).then((res) => {
        this.jdbcDriverList = res.data
      })
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          databaseInfoEdit(values).then((res) => {
            if (res.success) {
              this.$message.success('編輯成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
