//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { databaseInfoPage, databaseInfoDelete } from '@/api/modular/dbs/databaseInfoManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '數據庫名稱',
          dataIndex: 'dbName',
          scopedSlots: { customRender: 'dbName' }
        },
        {
          title: 'JDBC驅動類型',
          dataIndex: 'jdbcDriver',
          scopedSlots: { customRender: 'jdbcDriver' }
        },
        {
          title: '數據庫賬號',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' }
        },
        {
          title: 'JDBC的URL',
          dataIndex: 'jdbcUrl',
          scopedSlots: { customRender: 'jdbcUrl' }
        },
        {
          title: '創建時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '備註',
          dataIndex: 'remarks',
          scopedSlots: { customRender: 'remarks' }
        }
      ],
      loadData: parameter => {
        return databaseInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      Loading: false,
      jdbcDriverList: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
    if (this.hasPerm('databaseInfo:edit') || this.hasPerm('databaseInfo:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    sysDictTypeDropDown () {
      const params = {
        code: 'jdbc_driver'
      }
      sysDictTypeDropDown(params).then((res) => {
        this.jdbcDriverList = res.data
      })
    },
    jdbcDriverFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.jdbcDriverList.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    databaseInfoDelete (record) {
      this.Loading = true
      databaseInfoDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      }).finally((res) => {
        this.Loading = false
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
